import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent implements OnInit {

  exp = new Date().getFullYear() - new Date("2019-01-12").getFullYear();

  constructor() {
  }

  ngOnInit(): void {
  }

}
