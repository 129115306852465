<div id="footer">
  <div class="wrapper">
    <div class="category">
      <a href="#about-me">O mnie</a>
    </div>
    <div class="category">
      <a href="#career">Kariera</a>
    </div>
    <div class="category">
      <a href="#skills">Umiejętności</a>
    </div>
  </div>
  <div class="line">
  </div>
  <div class="contact-wrapper">
    <div class="contact">
      <i class="fas fa-envelope"></i>
      <div class="description">Email</div>
      <div class="data">
        mateusz.skrzypczyk@hotmail.com
      </div>
    </div>
    <div class="contact">
      <i class="fas fa-phone"></i>
      <div class="description">Telefon</div>
      <div class="data">
        +48 506 026 085
      </div>
    </div>
  </div>
</div>
