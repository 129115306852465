
<div class="media">
  <a class="icon" href="https://www.facebook.com/mateusz.skrzypczyk.9/" target="_blank">
    <i class="fab fa-facebook-f"></i>
  </a>
  <a class="icon" href="https://www.instagram.com/_matt.dev/" target="_blank">
    <i class="fab fa-instagram"></i>
  </a>
  <a class="icon" href="https://www.linkedin.com/in/mateusz-skrzypczyk/" target="_blank">
    <i class="fab fa-linkedin-in"></i>
  </a>
  <a class="icon" href="https://github.com/Fellway" target="_blank">
    <i class="fab fa-github"></i>
  </a>
</div>
