<div id="about-me">
  <div class="text-wrapper">
    <div class="text">
      <h2 class="section-header">O mnie</h2>
      <div class="section-text">
        <p>Cześć, nazywam się Mateusz i jestem programistą z {{exp}}-letnim doświadczeniem. Jako programista, jestem w
          stanie zapewnić rozwiązania dla różnych problemów, które wymagają wykorzystania technologii informatycznych.
          Posiadam szeroką gamę umiejętności programistycznych i języków programowania, takich jak Java, Kotlin,
          JavaScript, TypeScript. Mogę również pracować z różnymi narzędziami i technologiami, takimi jak bazy danych,
          frameworki webowe i rozwiązania chmurowe.
        </p>
        <p>
          Jestem zawsze zainteresowany zdobywaniem nowych umiejętności i poszerzaniem mojej wiedzy, aby sprostać
          wymaganiom projektów <br />i zadań, z którymi się spotykam. Współpracuję z klientami, aby zrozumieć ich potrzeby i
          dostarczyć rozwiązania, które spełniają ich wymagania.
          Moje doświadczenie obejmuje projektowanie <br/>i implementację oprogramowania, w tym tworzenie specyfikacji,
          testowanie, debugging i utrzymanie aplikacji.
        </p>
        <p>
          Jestem zaangażowany w swoją pracę i staram się zawsze dostarczać rozwiązania wysokiej jakości, które spełniają
          wymagania klientów. Wierzę, że najlepsze wyniki można osiągnąć poprzez stałą komunikację i współpracę między
          programistą a klientem.
          Jeśli potrzebujesz pomocy w projektowaniu i implementacji oprogramowania, jestem gotów pomóc. Skontaktuj się
          ze mną, aby omówić swoje wymagania i dowiedzieć się więcej o moich umiejętnościach i doświadczeniu.
        </p>
      </div>
    </div>
  </div>
  <div class="image-wrapper">
    <img class="image" src="../../../assets/images/me.webp">
  </div>
  <app-shape-devider position="bottom" color="black"></app-shape-devider>
</div>
