<div id="career">
  <h2 class="section-header">Kariera</h2>
  <div class="timeline">
    <div class="line">
    </div>
    <div class="timeline-point">
      <div class="year">
        <b>Grudzień 2018</b>
      </div>
    </div>
    <div class="box">
      <div class="frame right">
        <div class="dot"></div>
        <img class="image" src="../../assets/images/acc_logo.png"/>
      </div>
    </div>
    <div class="timeline-point">
      <div class="year">
        <b>Sierpień 2019</b>
      </div>
    </div>
    <div class="box">
      <div class="frame">
        <div class="dot"></div>
        <img class="image" src="../../assets/images/tt_logo.png"/>
      </div>
    </div>
    <div class="timeline-point">
      <div class="year">
        <b>Marzec 2020</b>
      </div>
    </div>
    <div class="box">
      <div class="frame right">
        <div class="dot"></div>
        <img class="image" src="../../assets/images/makolab_logo.png"/>
      </div>
    </div>
    <div class="box">
      <div class="timeline-point">
        <div class="year">
          <b>Maj 2021</b>
        </div>
      </div>
      <div class="frame left">
        <div class="text">
          <div class="dot-text">
          </div>
          <p class="section-text">Otworzenie własnej działalności gospodarczej</p>
        </div>
      </div>
    </div>
    <div class="timeline-point">
      <div class="year">
        <b>Czerwiec 2021</b>
      </div>
    </div>
    <div class="box">
      <div class="frame right">
        <div class="dot"></div>
        <img class="image" src="../../assets/images/empik_logo_new.png"/>
      </div>
    </div>
  </div>
  <app-shape-devider position="bottom" color="white"></app-shape-devider>
</div>
