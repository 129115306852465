<div id="skills">
  <h2 class="section-header">Umiejętności</h2>
  <div class="technology">
    <h3 class="subheader">Technologie</h3>
    <div class="grid">
      <div class="skill">
        <i class="fab fa-java"></i>
        <div class="skill-description">
          Java
        </div>
      </div>
      <div class="skill">
        <i class="fab fa-js"></i>
        <div class="skill-description">
          JavaScript
        </div>
      </div>
      <div class="skill">
        <i class="fab fa-react"></i>
        <div class="skill-description">
          React
        </div>
      </div>
      <div class="skill">
        <i class="fab fa-angular"></i>
        <div class="skill-description">
          Angular
        </div>
      </div>
      <div class="skill">
        <i class="fab fa-html5"></i>
        <div class="skill-description">
          HTML
        </div>
      </div>
      <div class="skill">
        <i class="fab fa-css3-alt"></i>
        <div class="skill-description">
          CSS
        </div>
      </div>
    </div>
  </div>

  <div class="tools">
    <h3 class="subheader">Narzędzia</h3>
    <div class="grid">
      <div class="skill">
        <i class="fab fa-git-alt"></i>
        <div class="skill-description">
          Git
        </div>
      </div>
      <div class="skill">
        <i class="fab fa-docker"></i>
        <div class="skill-description">
          Docker
        </div>
      </div>
      <div class="skill">
        <i class="fab fa-linux"></i>
        <div class="skill-description">
          Linux
        </div>
      </div>
      <div class="skill">
        <i class="fab fa-jenkins"></i>
        <div class="skill-description">
          Jenkins
        </div>
      </div>
      <div class="skill">
        <i class="fas fa-database"></i>
        <div class="skill-description">
          Database
        </div>
      </div>
      <div class="skill">
        <i class="fab fa-jira"></i>
        <div class="skill-description">
          Jira
        </div>
      </div>
    </div>
  </div>

  <div class="language">
    <h3 class="subheader">Języki</h3>
    <div class="grid">
      <div class="skill">
        <i class="fas fa-thermometer-full"></i>
        <div class="skill-description">
          Polski
        </div>
        <div class="experience">
          Ojczysty
        </div>
      </div>
      <div class="skill">
        <i class="fas fa-thermometer-half"></i>
        <div class="skill-description">
          Angielski
        </div>
        <div class="experience">
          B2
        </div>
      </div>
    </div>
  </div>
</div>
